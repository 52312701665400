//auth
export const SHOW_LOADING = "SHOW_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";

//toast
export const SHOW_TOAST = "SHOW_TOAST";

//Dashboard
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

//admin types
export const GET_ADMIN_DATA = "GET_ADMIN_DATA"
export const CHANGE_ADMIN_PASSWORD = "CHANGE_ADMIN_PASSWORD"

//category Types

export const ADD_CATEGORY = "ADD_CATEGORY"

//splash type
export const GET_SPLASH_DATA = "ADD_CATEGORY"
export const ADD_SPLASH = "ADD_SPLASH"
export const EDIT_SPLASH = "EDIT_SPLASH"
export const DELETE_SPLASH_IMAGE = "DELETE_SPLASH_IMAGE"

//FiceTV type
export const GET_FICETV_DATA = "GET_FICETV_DATA"
export const DELETE_MEDIA = "DELETE_MEDIA"
export const ADD_FICETV_DATA = "ADD_FICETV_DATA"
export const EDIT_MEDIA = "EDIT_MEDIA"

//  professional types
export const GET_PROFESSIONALS = "GET_PROFESSIONALS"
export const UPDATE_PROFESSIONAL_STATUS = "UPDATE_PROFESSIONAL_STATUS"
export const GET_SERVICES = "GET_SERVICES"
export const GET_PORTFOLIO = "GET_PORTFOLIO"
export const DELETE_POST = "DELETE_POST"
export const GET_CONTACT = "GET_CONTACT"
export const ADD_POST = "ADD_POST"
export const EDIT_POST = "EDIT_POST"
export const DELETE_REPORTPOST = "DELETE_REPORTPOST"

// symptoms type
export const GET_SYMPTOM_DATA = "GET_SYMPTOM_DATA"
export const ADD_SYMPTOM = "ADD_SYMPTOM"
export const EDIT_SYMPTOM = "EDIT_SYMPTOM"
export const DELETE_SYMPTOM = "DELETE_SYMPTOM"
export const DRAG_SYMPTOM = "DRAG_SYMPTOM"

//report type
export const GET_REPORT = "GET_REPORT"
export const DELETE_REPORT = "DELETE_REPORT"

// bokkings types
export const GET_BOOKINGS = "GET_BOOKINGS"

//jobs types
export const GET_JOBS = "GET_JOBS"

//users types
export const GET_USER_DATA = "GET_USER_DATA"
export const GET_ALL_GENDERS = "GET_ALL_GENDERS"
export const DISABLE_USER = "DISABLE_USER"
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const VERIFY_USERS = "VERIFY_USERS"
export const SEND_NOTIFY = "SEND_NOTIFY"
export const GET_THUMB = "GET_THUMBs"
export const GET_USER_DETAIL = "GET_USER_DETAIL"

//interest types
export const GET_INTEREST_DATA = "GET_INTEREST_DATA"
export const ADD_INTEREST = "ADD_INTEREST"
export const UPDATE_INTEREST = "UPDATE_INTEREST"
export const UPDATE_INTEREST_STATUS = "UPDATE_INTEREST_STATUS"
export const DRAG_INTERESTS = "DRAG_INTERESTS"

//connection type
export const GET_CONNECTION_DATA = "GET_CONNECTION_DATA"
export const GET_SINGLE_CONNECTION_DATA = "GET_SINGLE_CONNECTION_DATA"
export const ADD_COONECTION_TYPE = "ADD_COONECTION_TYPE"
export const DELETE_CONNECTION_IMAGE = "DELETE_CONNECTION_IMAGE"
export const UPDATE_CONNECTION_TYPE = "UPDATE_CONNECTION_TYPE"
export const ADD_CONNECTION_IMAGE = "ADD_CONNECTION_IMAGE"
export const UPDATE_CONNECTION_IMAGE = "UPDATE_CONNECTION_IMAGE"
export const DRAG_IMAGES_DATA = "DRAG_IMAGES_DATA"

//interested_in type
export const ADD_INTERESTED_IN = "ADD_INTERESTED_IN"
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY"
export const GET_INTERESTED_DATA = "GET_INTERESTED_DATA"
export const GET_SUBCATEGORY_DATA = "GET_SUBCATEGORY_DATA"
export const EDIT_CATEGORY = "EDIT_CATEGORY"
export const DELETE_INTRESTED_IN = "DELETE_INTRESTED_IN"

//FAQ
export const GET_QUESTION_ANSWER = "GET_QUESTION_ANSWER"
export const ADD_FAQ = "ADD_FAQ"
export const DELETE_FAQ = "DELETE_FAQ"
export const UPDATE_FAQ = "UPDATE_FAQ"

//term condition
export const GET_TERM_CONDITION = "GET_TERM_CONDITION"
export const UPDATE_TERM_CONDITION = "UPDATE_TERM_CONDITION"

//profile verification
export const GET_PROFILE_VERIFICATION = "GET_PROFILE_VERIFICATION"
export const UPDATE_VERIFICATION_IMAGE = "UPDATE_VERIFICATION_IMAGE"
export const ACTIVATE_VERIFICATION_STATUS = "ACTIVATE_VERIFICATION_STATUS"
export const DELETE_VERIFICATION_IMAGE = "DELETE_VERIFICATION_IMAGE"